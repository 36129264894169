::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
