.btn {
	width: 126px;
	padding: 11px 8px;

	border-radius: 5px;

	font-family: Lato;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;

	white-space: nowrap;

	@include media-breakpoint-up(lg) {
		width: 210px;
		padding-top: 18px;
		padding-bottom: 18px;

		border-radius: 8px;

		font-size: 20px;
		line-height: 24px;
	}
}
