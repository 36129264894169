#blog {
	margin-bottom: 58px;
	padding-left: 35px;
	padding-right: 35px;

	.blog-container {
		position: relative;
		padding-top: 45px;
		padding-bottom: 70px;

		background: #f5f7fe;
		border-radius: 30px;
	}

	.blog-rocketman {
		position: absolute;
		top: -72px;
		right: 18px;
		width: 144px;
		height: 144px;
	}

	.section-label {
		margin-bottom: 19px;
	}

	.section-title {
		margin-bottom: 43px;
	}

	.blog-box {
		margin-right: 30px;

		a {
			text-decoration: none;
		}

		img {
			margin-bottom: 15px;

			border-radius: 8px;
		}

		span {
			margin-bottom: 4px;

			font-family: Lato;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 26px;

			color: var(--bs-normal);
		}

		p {
			max-width: 261px;

			font-family: Lato;
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 24px;

			color: var(--bs-head);
		}
	}

	@include media-breakpoint-up(md) {
		margin-bottom: 61px;
		padding-left: 55px;
		padding-right: 55px;

		.blog-container {
			padding-top: 49px;
		}

		.section-title {
			margin-bottom: 32px;
		}
	}

	@include media-breakpoint-up(lg) {
		margin-bottom: 142px;

		.blog-container {
			padding-top: 86px;
		}

		.blog-rocketman {
			top: -200px;
			left: 0;
			width: 350px;
			height: 350px;
		}

		.section-label {
			margin-bottom: 32px;
		}

		.section-title {
			margin-bottom: 65px;
		}
	}
}
