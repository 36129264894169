.section-label {
	width: fit-content;
	padding: 8px 25px;

	background: #e3e8fa;
	border-radius: 60px;

	font-family: Lato;
	font-style: normal;
	font-weight: 600;
	font-size: 11px;
	line-height: 13px;
	text-transform: uppercase;

	color: #0044eb;
}
