#roadmap {
	max-width: 390px;
	padding-top: 20px;
	padding-left: 35px;
	padding-right: 35px;
	margin-bottom: 54px;

	.section-label {
		margin-bottom: 20px;
	}

	.section-title {
		margin-bottom: 27px;
	}

	.phase-list {
		margin-bottom: -20px !important;
	}

	.phase-box {
		max-width: 320px;
		margin-bottom: 20px;
		padding-top: 28px;
		padding-bottom: 28px;

		background: #f5f7fe;
		border: 1px solid #c9d5ff;
		border-radius: 16px;
	}

	.phase-title {
		margin-bottom: 7px;

		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		line-height: 48px;

		color: var(--bs-head);
	}

	.phase-season {
		margin-bottom: 19px;

		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 26px;

		color: var(--bs-normal);
	}

	ul {
		li {
			i {
				margin-right: 10px;

				color: #0044eb;
			}

			span {
				font-family: Lato;
				font-style: normal;
				font-weight: normal;
				font-size: 13px;
				line-height: 40px;
			}
		}
	}

	@include media-breakpoint-up(md) {
		max-width: 768px;
		padding-left: 55px;
		padding-right: 55px;
		margin-bottom: 84px;
		margin-left: 0;

		.section-label {
			margin-bottom: 20px;
		}

		.section-title {
			margin-bottom: 24px;
		}

		.phase-list {
			margin-right: -12px !important;
			margin-bottom: -12px !important;
		}

		.phase-box {
			height: subtract(100%, 12px);
			margin-bottom: 12px;
			margin-right: 12px;
		}

		.phase-title {
			font-size: 36px;
			line-height: 43px;
		}
	}

	@include media-breakpoint-up(lg) {
		max-width: 770px;
		margin-bottom: 154px;

		.section-label {
			margin-bottom: 38px;
		}

		.section-title {
			margin-bottom: 68px;
		}

		.phase-list {
			margin-right: -30px !important;
			margin-bottom: -30px !important;
		}

		.phase-box {
			max-width: 370px;
			height: 480px;
			margin-bottom: 30px;
			margin-right: 30px;
			padding-top: 55px;
			padding-bottom: 55px;
		}

		.phase-title {
			margin-bottom: 0;

			font-size: 40px;
			line-height: 48px;
		}

		.phase-season {
			margin-bottom: 41px;
		}
	}

	@include media-breakpoint-up(xxl) {
		max-width: 1570px;
	}
}
