#about {
	padding-top: 20px;
	padding-left: 35px;
	padding-right: 24px;
	margin-bottom: 40px;

	.section-label {
		margin-bottom: 18px;
	}

	.section-title {
		margin-bottom: 18px;
	}

	.section-description {
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		line-height: 34px;

		color: #404046;
	}

	@include media-breakpoint-up(md) {
		padding-left: 55px;
		padding-right: 77px;
		margin-bottom: 34px;
	}

	@include media-breakpoint-up(lg) {
		max-width: 905px;
		margin-bottom: 128px;

		.section-label {
			margin-bottom: 27px;
		}

		.section-title {
			margin-bottom: 45px;
		}

		.section-description {
			font-size: 24px;
			line-height: 39px;
		}
	}
}
