#art {
	padding-top: 20px;
	padding-left: 35px;
	padding-right: 35px;
	margin-bottom: 84px;

	.section-label {
		margin-bottom: 17px;
	}

	.section-title {
		margin-bottom: 25px;
	}

	.art-box {
		width: 100%;
		height: 50vh;

		img {
			object-fit: contain;
			cursor: pointer;
		}
	}

	@include media-breakpoint-up(md) {
		padding-left: 55px;
		padding-right: 55px;
	}

	@include media-breakpoint-up(lg) {
		margin-bottom: 153px;

		.section-label {
			margin-bottom: 32px;
		}

		.section-title {
			margin-bottom: 64px;
		}

		.art-lg-container {
			& > .row {
				margin-right: -30px;
				margin-bottom: -30px;

				.art-box {
					width: subtract(100%, 30px);
					height: subtract(50vmin, 30px);
					margin-right: 30px;
					margin-bottom: 30px;

					box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);

					img {
						border-radius: 8px;

						object-fit: cover;
					}
				}
			}
		}

		.modal {
			.slick-list {
				border-radius: 30px;
			}

			.art-box {
				height: 90vmin;
				padding: 1vmin;

				background: rgba(0, 0, 0, 0.8);
				box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
			}
		}
	}
}
