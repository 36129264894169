#how-to-buy {
	padding-top: 20px;
	padding-left: 35px;
	padding-right: 24px;
	margin-bottom: 43px;

	background-image: url('/assets/images/circle.png');
	background-repeat: no-repeat;
	background-size: 568px;
	background-position: top 80px left 106px;

	$step-label-size: 36px;
	$step-label-me: 18px;

	.section-label {
		margin-bottom: 23px;
	}

	.section-title {
		margin-bottom: 24px;
	}

	.panel-text {
		ul {
			li {
				&:not(:last-child) {
					margin-bottom: 17px;
				}
			}
		}

		.step {
			margin-bottom: 8px;
		}

		.step-label {
			display: flex;
			align-items: center;
			justify-content: center;
			width: $step-label-size;
			height: $step-label-size;
			margin-right: $step-label-me;
			margin-bottom: 12px;

			background: #e6ecfd;
			border-radius: 99999px;

			font-family: Lato;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 22px;

			color: #0044eb;
		}

		.step-title {
			font-family: Lato;
			font-style: normal;
			font-weight: 600;
			font-size: 24px;
			line-height: 29px;

			color: var(--bs-head);
		}

		.step-description {
			font-family: Lato;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 26px;
			word-break: break-word;

			color: var(--bs-normal);
		}
	}

	.panel-action {
		margin-top: 26px;

		.btn-uniswap {
			margin-right: 14px;
		}
	}

	@include media-breakpoint-up(md) {
		padding-left: 55px;
		padding-right: 108px;
		margin-bottom: 36px;

		background-position: top 80px right -101px;

		.section-title {
			margin-bottom: 42px;
		}

		.panel-text {
			ul {
				li {
					&:not(:last-child) {
						margin-bottom: 32px;
					}
				}
			}

			.step {
				margin-bottom: 11px;
			}

			.step-description {
				margin-left: add($step-label-size, $step-label-me);
			}
		}

		.panel-action {
			margin-top: 35px;

			.btn-uniswap {
				margin-right: 26px;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		max-width: 1170px;
		margin-bottom: 138px;

		background: none;

		.section-label {
			margin-bottom: 34px;
		}

		.section-title {
			margin-bottom: 49px;
		}

		.panel-text {
			max-width: 424px;
		}

		.panel-action {
			max-width: 568px;

			.btn-uniswap {
				margin-right: 31px;
			}
		}
	}
}
